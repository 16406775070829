







































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";
import UpsertAppointmentView from "./UpsertAppointmentView.vue";
import CreateAppointmentButton from "./CreateAppointmentButton.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { AppointmentGraphQLService } from "../../services/AppointmentGraphQLService";
import { FormRequestHandler } from "../../../forms/FormRequestHandler";
import { Plant } from "@/plant/services/PlantService";
import { DateUtils } from "@/utils/DateUtils";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";
import { FormResponse } from "@/forms/FormResponse";

@Component<PlantAppointmentsCard>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    DeleteDialog,
    HoverableDatatable,
    UpsertAppointmentView,
    CreateAppointmentButton
  },
  props: ["plant", "plantRequest", "element"],
  watch: {
    plant() {
      this.loadAppointments();
    },
    element(newValue: any) {
      if (newValue) {
        this.loadAppointments();
      }
    }
  }
})
export default class PlantAppointmentsCard extends Vue {
  protected plant!: Plant;
  protected appointments: any[] = [];
  protected upsertDialogShown = false;
  protected appointmentToEdit: any = null;
  protected appointmentType: string = "LOOSE";
  protected isDeleteDialogShown = false;
  protected toDeleteAppointment: any = {};
  protected cancellationReason: string = "";
  protected appointmentTableHeaders = [
    { text: "Datum", value: "date", sortable: true, width: "20%" },
    { text: "Typ", value: "type", sortable: true },
    { text: "Dauer", value: "duration", sortable: true, width: "8%" },
    { text: "Termingrund", value: "reason" },
    { text: "Stornierungsgründe", value: "cancellationReason" },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "80px"
    }
  ];
  protected appointmentsRequest = new FormResponse<any[]>();

  protected connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  protected appointmentService = new AppointmentGraphQLService(this.connection);

  protected get canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }

  protected get maintenanceDisabled() {
    return this.createMaintenanceInfo !== "";
  }

  protected get createMaintenanceInfo() {
    const plant = this.plant as any;

    if (!plant) {
      return "Die Anlage konnte nicht geladen werden. Daher kann auch kein Termin erstellt werden.";
    } else if (!plant.plantContract || !plant.plantContract.hasContract) {
      return "Die Anlage hat keinen Wartungsvertrag. Daher kann auch kein Wartungstermin erstellt werden.";
    } else if (plant.plantContract.contractQuitted) {
      return "Der Wartungsvertrag ist bereits abgelaufen. Daher kann auch kein Wartungstermin erstellt werden.";
    } else if (this.appointments.some(a => a.type === "MAINTENANCE")) {
      return "Es existiert bereits ein offener Wartungstermin. Es kann immer nur ein Wartungstermin pro Anlage existieren.";
    }

    return "";
  }

  protected loadAppointments() {
    if (!this.plant.id) {
      return;
    }

    const request = this.appointmentService.getAppointmentsByPlantId(
      this.plant.id
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.appointments = response.data;
          this.orderAppointments();
        }
      },
      "get-appointments-failed"
    );
  }

  protected handleAppointmentCreated(appointment: any) {
    this.upsertDialogShown = false;
    this.addAppointment(appointment);
  }

  protected handleAppointmentUpdated(appointment: any) {
    this.upsertDialogShown = false;
    this.appointments = this.appointments.filter(a => a.id !== appointment.id);
    this.addAppointment(appointment);
  }

  protected addAppointment(appointment: any) {
    this.appointments.push(appointment);
    this.orderAppointments();
  }

  protected orderAppointments() {
    this.appointments.sort((a, b) => {
      return DateUtils.toISOString(a.date).localeCompare(
        DateUtils.toISOString(b.date)
      );
    });
  }

  protected getDate(appointment: any) {
    if (appointment.plannedDate) {
      return DateUtils.formatDate(appointment.plannedDate);
    }
    return DateUtils.formatDate(appointment.date);
  }

  protected parseType(type: string) {
    switch (type) {
      case "MAINTENANCE":
        return "Wartung";
      case "LOOSE":
      default:
        return "Termin";
    }
  }

  protected parseDuration(durationInMinutes: number) {
    return (durationInMinutes / 60).toFixed(2) + " h";
  }

  protected openUpsertAppointmentDialog(appointment?: any, type?: string) {
    this.appointmentType = type ?? "LOOSE";
    this.appointmentToEdit = appointment;
    this.upsertDialogShown = true;
  }

  protected openDeleteAppointmentDialog(appointment: any) {
    this.toDeleteAppointment = appointment;
    this.isDeleteDialogShown = true;
  }

  protected deleteAppointment(appointment: any) {
    const request = this.appointmentService.cancelAppointment(
      appointment.id,
      this.cancellationReason
    );
    FormRequestHandler.handle(
      request,
      response => {
        this.isDeleteDialogShown = false;
        if (!response.loading && !response.error) {
          this.toDeleteAppointment = {};
          this.cancellationReason = "";
          this.loadAppointments();
        }
      },
      "update-plant-failed"
    );
  }
}
