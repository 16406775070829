


























































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import DatePicker from "@/components/basic/DatePicker.vue";
import TimePicker from "@/components/basic/TimePicker.vue";
import SimpleCheckbox from "@/components/form/SimpleCheckbox.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { CountriesStorageHandler } from "../../../storage/storageHandlers/CountriesStorageHandler";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

import { AppointmentGraphQLService } from "@/tourPlanner/services/AppointmentGraphQLService";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { CustomerServiceReportStorageHandler } from "@/storage/storageHandlers/CustomerServiceReportStorageHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { Auth } from "@/common/utils/Auth";

interface Address {
  addressRemarks?: string;
  street?: string;
  zip?: string;
  city?: string;
  country?: string;
  state?: string;
}

@Component<UpsertAppointmentView>({
  components: {
    ValidationObserver,
    Container,
    Column,
    Row,
    Page,
    Card,
    DatePicker,
    TimePicker,
    SimpleCheckbox,
    RequestButton,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedCombobox
  },
  watch: {
    plant() {
      this.initAddress();
    },
    appointment() {
      this.initAddress();
      this.initAppointmentData();
    },
    open() {
      this.clear();
    }
  },
  props: ["plant", "appointment", "appointmentType", "open"]
})
export default class UpsertAppointmentView extends Vue {
  // Props
  protected plant!: any;
  protected appointment?: any;
  protected appointmentType!: string;

  // Data
  protected step = 1;
  protected upsertAppointmentRequest = new FormResponse<string>();

  // Address Fields
  protected differentAddress = false;
  protected addressRemarks = "";
  protected street = "";
  protected zip = "";
  protected city = "";
  protected country = "";
  protected state = "";

  // Appointment Fields
  protected date = "";
  protected reasons: any[] = [];
  protected duration = 0;
  protected sampleNeeded = true;

  protected countries: SelectionItem[] = [];
  protected states: SelectionItem[] = [];
  protected allReasons: SelectionItem[] = [];

  protected appointmentService = new AppointmentGraphQLService(
    new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
  );

  protected get title() {
    return this.appointment
      ? `${this.appointmentTypeText} bearbeiten`
      : `Neuen ${this.appointmentTypeText} anlegen`;
  }

  protected get appointmentTypeText() {
    return this.appointmentType === "MAINTENANCE" ? "Wartungstermin" : "Termin";
  }

  protected get upsertButtonText() {
    return this.appointment ? "Speichern" : "Erstellen";
  }

  protected get plantAddress() {
    const street = this.plant.addressStreet;
    const zip = this.plant.addressZip;
    const city = this.plant.addressCity;
    const country = this.parseCountry(this.plant.addressCountry);

    return `${street}, ${zip} ${city} (${country})`;
  }

  protected get dateText() {
    return !!this.date ? DateUtils.format(this.date) : "";
  }

  protected get durationText() {
    return DateUtils.timeToString(this.duration);
  }

  protected get resonsWithAddditionalField() {
    return this.reasons.filter(r => r.value && r.value.additionalTextField);
  }

  protected get formInvalid() {
    return this.date === "" || this.duration === 0;
  }

  protected mounted() {
    this.loadCountries();
    this.loadReasons();
    this.initAddress();
    this.initAppointmentData();
  }

  protected loadCountries() {
    this.countries = CountriesStorageHandler.getAllCountries();
  }

  protected loadReasons() {
    this.allReasons = CustomerServiceReportStorageHandler.getAllReasons();
  }

  protected initAddress() {
    const plantAddress = this.getAddress(this.plant);
    const appointmentAddress = this.getAddress(this.appointment);
    const addressToUse = appointmentAddress ?? plantAddress;

    this.differentAddress = this.compareAddresses(
      plantAddress,
      appointmentAddress
    );

    this.setAddress(addressToUse);
  }

  protected getAddress(plantOrAppointment: any): Address | undefined {
    if (!plantOrAppointment) {
      return undefined;
    }

    const appointmentRemarks = plantOrAppointment.addressAddition;
    const plantRemarks = plantOrAppointment.addressRemarks;

    return {
      addressRemarks: (appointmentRemarks || plantRemarks) ?? "",
      street: plantOrAppointment.addressStreet ?? "",
      zip: plantOrAppointment.addressZip ?? "",
      city: plantOrAppointment.addressCity ?? "",
      country: this.encodeCountry(plantOrAppointment.addressCountry) ?? "",
      state: plantOrAppointment.addressState ?? ""
    };
  }

  protected setAddress(address?: Address) {
    this.addressRemarks = address?.addressRemarks ?? "";
    this.street = address?.street ?? "";
    this.zip = address?.zip ?? "";
    this.city = address?.city ?? "";
    this.country = address?.country ?? "";
    this.state = address?.state ?? "";

    this.loadStatesOfCountry(this.country);
  }

  protected compareAddresses(a?: Address, b?: Address) {
    if (a === b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    return (
      a.street !== b.street ||
      a.zip !== b.zip ||
      a.city !== b.city ||
      a.country !== b.country ||
      a.state !== b.state
    );
  }

  protected initAppointmentData() {
    if (!this.appointment) {
      return;
    }

    this.date = DateUtils.toISOString(this.appointment.date);
    this.duration = this.appointment.duration;
    this.sampleNeeded = this.appointment.sampleNeeded;

    this.reasons = CustomerServiceReportStorageHandler.parseReasons(
      this.appointment.reason
    );
  }

  protected parseCountry(countryId: string) {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }

  protected encodeCountry(countryName: string) {
    return CountriesStorageHandler.getCountryIdFromName(countryName);
  }

  protected loadStatesOfCountry(countryId: string) {
    this.states = CountriesStorageHandler.getStatesFromCountry(countryId);
  }

  protected handleDifferentAddressChecked(checked: boolean) {
    this.differentAddress = checked;

    if (!checked) {
      const plantAddress = this.getAddress(this.plant);
      this.setAddress(plantAddress);
    }
  }

  protected async upsertAppointment() {
    if (this.appointment) {
      this.updateAppointment();
    } else {
      this.createAppointment();
    }
  }

  protected async createAppointment() {
    const combinedReasons = CustomerServiceReportStorageHandler.stringifyReasons(
      this.reasons
    );

    const params: any = {
      plantId: this.plant.id,
      tourPlannerId: Auth.userId,
      addressLat: this.differentAddress ? null : this.plant.addressLat,
      addressLng: this.differentAddress ? null : this.plant.addressLng,
      addressAddition: this.addressRemarks,
      addressStreet: this.street,
      addressZip: this.zip,
      addressCity: this.city,
      addressCountry: this.country,
      addressState: this.state,
      date: this.date,
      reason: combinedReasons,
      duration: this.duration,
      sampleNeeded: this.sampleNeeded,
      processingState: "OPEN"
    };

    const r = await FormRequestHandler.handle(
      this.appointmentService.createLooseAppointment(
        params,
        this.appointmentType
      ),
      response => {
        this.upsertAppointmentRequest = response;
      },
      "Termin konnte nicht erstellt werden"
    );

    if (r.success) {
      this.$emit("appointmentCreated", {
        id: r.data,
        type: this.appointmentType,
        ...params
      });
    }
  }

  protected async updateAppointment() {
    const combinedReasons = CustomerServiceReportStorageHandler.stringifyReasons(
      this.reasons
    );

    const params: any = {
      appointmentId: this.appointment.id,
      date: this.date,
      address: {
        addition: this.addressRemarks,
        street: this.street,
        zip: this.zip,
        city: this.city,
        country: this.country,
        state: this.state,
        lat: this.differentAddress ? null : this.plant.addressLat,
        lng: this.differentAddress ? null : this.plant.addressLng
      },
      reason: combinedReasons,
      duration: this.duration,
      sampleNeeded: this.sampleNeeded
    };

    const r = await FormRequestHandler.handle(
      this.appointmentService.updateAppointmentData(params),
      response => {
        this.upsertAppointmentRequest = response;
      },
      "Termin konnte nicht bearbeitet werden"
    );

    if (r.success) {
      const appointment = {
        ...params,
        id: r.data,
        addressLat: this.differentAddress ? null : this.plant.addressLat,
        addressLng: this.differentAddress ? null : this.plant.addressLng,
        addressAddition: this.addressRemarks,
        addressStreet: this.street,
        addressZip: this.zip,
        addressCity: this.city,
        addressCountry: this.country,
        addressState: this.state
      };

      this.$emit("appointmentUpdated", appointment);
    }
  }

  protected clear() {
    this.step = 1;
    this.upsertAppointmentRequest = new FormResponse<string>();
    this.differentAddress = false;
    this.addressRemarks = "";
    this.street = "";
    this.zip = "";
    this.city = "";
    this.country = "";
    this.state = "";
    this.date = "";
    this.reasons = [];
    this.duration = 0;
    this.sampleNeeded = false;
  }

  protected closeDialog() {
    this.$emit("closed");
  }
}
