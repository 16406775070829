







































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

interface AppointmentType {
  title: string;
  value: string;
  disabled: boolean;
}

@Component<CreateAppointmentButton>({
  components: {
    Row,
    Column
  },
  props: ["maintenanceDisabled", "maintenanceInfo"]
})
export default class CreateAppointmentButton extends Vue {
  protected maintenanceDisabled?: boolean;
  protected maintenanceInfo?: string;

  protected get items(): AppointmentType[] {
    return [
      {
        title: "Wartungstermin",
        value: "MAINTENANCE",
        disabled: this.maintenanceDisabled ?? false
      },
      { title: "Anderer Termin", value: "LOOSE", disabled: false }
    ];
  }

  protected handleAppointmentTypeSelected(type: AppointmentType) {
    if (type.disabled) {
      return;
    }

    this.$emit("appointmentTypeSelected", type.value);
  }
}
